export default  {
    system:{
        en:'English',
        id:'Indonesian',
        about:'Tentang',
        product:'Produk',
        enter:'Please enter',
        btn:'Demo Admin',
        bnt2:'Demo Klien',
        required: "Input tidak boleh kosong",
        tef_ii: "Mohon masukkan alamat email yang valid!",
        placeholder:'Type here...',
        tips:'Tips',
        confirm:'Konfirmasi'
    },
    home:{
        title1:'Trading Smart Managing Right',
        title:'TradeWise CRM',
        desc1:"Tradewise CRM menawarkan analisis data yang kuat untuk meningkatkan pengambilan keputusan yang informasional. Ini menyediakan konfigurasi strategi pemasaran yang fleksibel, mengotomatiskan proses KYC melalui pengenalan wajah, dan memiliki platform trading bawaan yang terintegrasi secara mulus dengan MT4, MT5, dan sistem trading opsi.",
        desc2:'Kami berdedikasi untuk menawarkan solusi perangkat lunak berkualitas tinggi untuk memenuhi kebutuhan bisnis pelanggan kami. Sebagai perusahaan perangkat lunak terkemuka, kami telah membangun tim teknis yang excellent dan tim layanan pelanggan profesional yang mampu membantu broker dalam mengelola pelanggan, organisasi, dan karyawan dengan efisien, sehingga mencapai pertumbuhan bisnis yang cepat dan ekspansi yang stabil.',
        subTitle1:'Sistem Trading Bawaan',
        subTitle2:'Penyebaran Aplikasi Seluler',
        subTitle3:'Multi-Platform dan Multi-Server Trading',
        subTitle4:'Kustomisasi Agen tanpa Batas Tingkat',
        subTitle5:'Otomatisasi KYC dengan Pengenalan Wajah',
        subTitle6:'Layanan Pengembangan Kustom',
        subTitle7:'Kami menawarkan fitur khusus yang dirancang untuk memenuhi kebutuhan unik Anda. Layanan pengembangan kustom kami melayani berbagai kebutuhan, mendukung sistem trading bawaan dan berbagai kegiatan operasional lainnya.',
        title3:"Mitra",
        contact:'Hubungi Kami',
        is_out_in:"Minta Informasi Lebih Lanjut",
        TM:'All rights reserved.© 2023 TradeWise Software PTE. Limited. A registered company in Singapore.',
        sys_name:'TradeWise CRM',
        subTitle9:'Solutions for Brokers',
        subTitle10:'Efficient Way to Improve Your Management and Boost YourProductivity',
        title4:'Multi-Platform & Multi-Server Trading',
        desc:'Mendukung berbagai platform trading, termasuk MT4, MT5, dan Options, memberikan tampilan yang terintegrasi.',
        desc3:'Mendukung manajemen terpadu dana klien di berbagai platform dan server trading, menyederhanakan proses setoran dan penarikan.',
        desc4:'Memungkinkan pengguna untuk membuka dan mengelola beberapa akun trading di berbagai server trading di bawah profil pengguna yang sama.',
        desc5:'Pengaturan jenis akun yang fleksibel, seperti akun Standard, ECN, dan STP.',
        title5:'Otomatisasi KYC dengan Pengenalan Wajah',
        desc6:'Persyaratan KYC yang dapat dikonfigurasi berdasarkan negara dan wilayah yang berbeda, dilengkapi dengan pengenalan dan verifikasi wajah secara real-time.',
        subTitle11:'Manajemen Dokumen Kepatuhan Global',
        desc7:'Mengonfigurasi dokumen yang diperlukan secara fleksibel berdasarkan persyaratan kepatuhan negara yang berbeda. Mendukung pengenalan otomatis berbasis OCR untuk berbagai dokumen identitas, termasuk kartu identitas, paspor, SIM, dan lain-lain, memastikan akurasi dan efisiensi dalam pemrosesan dokumen.',
        subTitle12:'Optimalisasi Proses KYC Otomatis',
        desc8:'Menggunakan pengenalan wajah dan verifikasi secara real-time untuk meningkatkan efisiensi validasi identitas pelanggan. Fitur ini mempercepat proses tinjauan, mengurangi kehilangan pelanggan, dan secara efektif menurunkan biaya tenaga kerja.',
        // title6:'Agent Customization with no level limit',
        desc9:'Membuat struktur komisi yang berbeda untuk semua agen untuk meningkatkan program referral Anda.',
        subTitle13:'Struktur Komisi yang Dapat Disesuaikan',
        subTitle14:'Pelacakan dan Manajemen Komisi Lintas Platform',
        subTitle15:'Mekanisme Reward Multi-Tier',
        subTitle16:'Opsi Pembayaran Komisi yang Fleksibel',
        desc10:'Secara mudah menyesuaikan struktur komisi sesuai permintaan untuk menyelaraskan program insentif dengan tujuan perusahaan Anda, meningkatkan keterlibatan dan motivasi agen.',
        desc11:'Mengcentralisasi pelacakan dan manajemen komisi di berbagai platform trading, memberikan gambaran komprehensif untuk membantu administrator memantau kinerja dengan efisien dan mengoptimalkan keterlibatan agen.',
        desc12:'Merancang sistem reward multi-tier yang fleksibel yang selaras dengan berbagai strategi operasional broker, memastikan program insentif responsif terhadap dinamika pasar dan meningkatkan produktivitas agen.',
        desc13:'Menawarkan berbagai metode pembayaran komisi, termasuk transaksi tunggal, pembayaran berkala, dan pembayaran dinamis berbasis kinerja, untuk memenuhi kebutuhan beragam agen dan meningkatkan fleksibilitas pembayaran.',
        // title7:'Mobile App Deployment',
        desc14:'Dikemas sebagai aplikasi seluler untuk akuisisi pelanggan melalui saluran seperti Google dan Facebook. Broker dapat memanfaatkan toko aplikasi, media sosial, dan mesin pencari untuk mendapatkan unduhan dengan tepat dan dengan biaya rendah per unduhan, melakukan transisi dari metode akuisisi pelanggan offline tradisional ke broker berbasis internet.',
        tel:'Tel',
        email:'Email',
        addr:'Address',
        name:'Nama Anda',
        reci:'recipient',
        msg:'Pesan Anda',
        your_email:'Email',
        sendMsg:'Kirim',
        sendSucc:'Terima kasih atas minat Anda pada Tradewise CRM dan atas pengiriman informasi Anda. Kami menghargai pertanyaan Anda dan sangat senang dapat membantu Anda. Tim kami akan meninjau pengajuan Anda dan akan segera menghubungi Anda secepat mungkin.'
    }
}