<template>
  <div :class="$store.state.style === 'black' ? 'footer' : 'footer normal'">
    <div class="sl">
      <div class="top">
        <div class="left">
          <div class="title">
            <p class="p22">{{$t('home.contact')}}</p>
            <p></p>
          </div>
          <div class="item" v-for="item in contactInfo" :key="item.id">
            <img
              :src="require(`@/assets/img/footer/${item.icon}`)"
              alt=""
            />
<!--            <img-->
<!--              v-show="$store.state.style === 'white'"-->
<!--              :src="require(`../../assets/img/footer/${item.icon1}`)"-->
<!--              alt=""-->
<!--            />-->
            <p class="p16 desc">{{ item.info }}</p>
          </div>
        </div>
        <div class="right">
          <div class="title">
            <p class="title p22">{{$t('home.is_out_in')}}</p>
            <p></p>
          </div>
          <div class="form-item">
            <label><p class="require">*</p><p>{{$t('home.name')}}:</p></label>
            <div class="item1">
              <input type="text" @blur="verify('name')" :placeholder="$t('system.placeholder')" v-model="submitInfo.name" />
              <p class="valid">{{!rules.name.valid ? $t('system.required') :''}}</p>
            </div>

          </div>

          <div class="form-item">
            <label><p class="require">*</p><p>{{$t('home.your_email')}}</p>:</label>
            <div class="item1">
              <input type="text"  :placeholder="$t('system.placeholder')"  @blur="verify('reqStr')" v-model="submitInfo.reqStr" />
<!--          $t('system.required')    -->
              <p class="valid" >{{!rules.reqStr.valid ? (submitInfo.reqStr ? $t('system.tef_ii'):$t('system.required')):''}}</p>
            </div>

          </div>
          <div class="form-item">
            <label><p class="require">*</p><p>{{$t('home.msg')}}:</p></label>
            <div class="item1">
              <input
                  type="text"
                  :placeholder="$t('system.placeholder')"
                  @blur="verify('phone')"
                  v-model="submitInfo.phone"/>
              <p class="valid" >{{!rules.phone.valid ? $t('system.required'):''}}</p>
            </div>
          </div>


          <div class="btn">
            <p class="p18" @click="sendMsg()">
              <!-- SEND MAIL -->
<!--              <a :href="`mailto:Akina@tradewisesoftware.com?@hotmail.com&name=${submitInfo.name}&message=${submitInfo.message}`">{{$t('home.sendMsg')}}</a>-->
              {{$t('home.sendMsg')}}
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p class="desc">
          {{$t('home.TM')}}
        </p>
      </div>
    </div>
    <div class="xs">
      <div class="top">
        <div class="right">
          <div class="title">
            <p class="title p22">{{$t('home.is_out_in')}}</p>
            <p></p>
          </div>
          <div class="form-item">
            <div :class="`item1 ${rules.name.valid ?'success':'error'}`">
              <label>
                <p class="require">*</p>
                <p>{{$t('home.name')}}:</p>
                </label>
              <input type="text" @blur="verify('name')"  :placeholder="$t('system.placeholder')"  v-model="submitInfo.name" />
            </div>
            <p class="valid">{{!rules.name.valid ? $t('system.required'):''}}</p>
          </div>
          <div class="form-item">
          <div class="item1">
            <label><p class="require">*</p><p>{{$t('home.your_email')}}:</p></label>
            <input type="text"  @blur="verify('reqStr')" :placeholder="$t('system.placeholder')" v-model="submitInfo.reqStr" />
          </div>
            <p class="valid">{{!rules.reqStr.valid ? (submitInfo.reqStr ? $t('system.tef_ii'):$t('system.required')):''}}</p>
          </div>
          <div class="form-item">
          <div :class="`item1 ${rules.phone.valid ?'success':'error'}`">
            <label><p class="require">*</p><p>{{$t('home.msg')}}:</p></label>
            <input
                type="text"
                @blur="verify('phone')"
                :placeholder="$t('system.placeholder')"
                v-model="submitInfo.phone"/>
          </div>
            <p class="valid">{{!rules.phone.valid ? $t('system.required'):''}}</p>
          </div>
          <!-- @click="submit" -->
          <div class="btn">
            <p class="p18" @click="sendMsg()">
              <!-- SEND MAIL -->
              <!--              <a :href="`mailto:Akina@tradewisesoftware.com?@hotmail.com&name=${submitInfo.name}&message=${submitInfo.message}`">{{$t('home.sendMsg')}}</a>-->
              {{$t('home.sendMsg')}}
            </p>
          </div>
        </div>
        <div class="left">
          <div class="title">
            <p class="p22">{{$t('home.contact')}}</p>
            <p></p>
          </div>
          <div class="item" v-for="item in contactInfo" :key="item.id">
            <img :src="require(`@/assets/img/footer/${item.icon}`)" alt=""/>

            <p class="p16 desc">{{ item.info }}</p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <p class="desc">
          {{$t('home.TM')}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {sendMail} from '@/api/settings/api.js';
import { MessageBox } from 'element-ui';
export default {
  data() {
    return {
      rules:{
        name:{
          valid:true,
        },
        phone:{
          valid:true,
        },
        reqStr:{
          valid:true,
        }
      },
      contactInfo: [
        {
          id: 1,
          icon: "icon (1).png",
          icon1: "icon1 (1).png",
          info: `${this.$t('home.tel')}:  0065 31388988`,
        },
        {
          id: 2,
          icon: "icon (2).png",
          icon1: "icon1 (2).png",
          info: `${this.$t('home.email')}: Akina@tradewisesoftware.com`,
        },
        {
          id: 3,
          icon: "icon (3).png",
          icon1: "icon1 (3).png",
          info: `${this.$t('home.addr')}: 987 Serangoon Road, Singapore, 328147`,
        },
      ],
      submitInfo: {
        name: "",
        reqStr:"",
        phone: "",
      },
    };
  },

  methods: {
    totrim: function () {
      let that = this
      that.submitInfo.name= that.submitInfo.name.trim()
      // that.submitInfo.phone= that.submitInfo.phone.trim();
      that.submitInfo.reqStr= that.submitInfo.reqStr.trim()
    },
    verify(key){
      if(key == 'reqStr'){
        let sign = /^([A-Za-z0-9_\-\.])+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.submitInfo.reqStr);
        if(!sign){
          this.rules[key].valid = false;
          return false;
        }
        this.rules[key].valid = true;
        return true;
      }
      if(this.submitInfo[key]){
        this.rules[key].valid = true;
        return true;
      }else{
        this.rules[key].valid = false;
        return false;
      }
    },
   // submit() {
   //    const nodemailer = require("nodemailer");
   //    var bodyParser = require("body-parser");
   //    let express = require("express");
   //    let app = express();
   //    app.use(bodyParser.urlencoded({ extended: false })); // 这一句会解决掉一个错误
   //
   //    app.get("/email", function (req, res) {
   //      let transporter = nodemailer.createTransport({
   //        service: "smtp.qiye.aliyun.com",
   //        port: 465,
   //        secure: false,
   //        auth: {
   //          user: "jiao.hu@zhihanyixing.com",
   //          pass: "1QAZ2WSx",
   //        },
   //      });
   //
   //      // send mail with defined transport object
   //      transporter
   //        .sendMail({
   //          from: "jiao.hu@zhihanyixing.com",
   //          to: '1445120694@qq.com', // 这里req.query获取get请求参数内容，若使用post请求则用req.body获取
   //          subject: 'hj',
   //          text: 'test',
   //        })
   //        .then((res) => {
   //          console.log('发送邮件', res);
   //        });
   //    });
   //    app.listen("465"); //开启服务端口号8081
   //  },
    async sendMsg(){

     //  if(this.submitInfo.name.replace(' ','') =='' || this.submitInfo.phone.replace(' ','')==''){
     //    window.alert(this.$t('system.required'));
     //     return ;
     //  }
     //  if(this.submitInfo.reqStr.replace(' ','') ==''){
     //    window.alert(this.$t('system.tef_ii'));
     //    return;
     //  }

      let go = this.verifyChild(['name','phone','reqStr']);
      if(!go) return;
      let res = await sendMail(this.submitInfo);
      if(res.code == '200'){
        MessageBox.confirm(`${this.$t('home.sendSucc')}`,`${this.$t('system.tips')}`,{
          showCancelButton:false,
          center:true,
          confirmButtonText:this.$t('system.confirm'),
          customClass:'prompt'
        });
          this.submitInfo= {
          name: "",
          phone: "",
          reqStr:"",
        }
      }
    },
     verifyChild  (list)  {
      let arr = [];
      list.forEach(v => {
        arr.push(this.verify(v));
      });
      return arr.every(v => v);
    }
  },
};
</script>
<style>
::v-deep .prompt .el-message-box__header .el-message-box__title{
  text-align: center !important;
}
.prompt .el-message-box__header >>>.el-message-box__title{
  text-align: center !important;
}
:deep(.prompt .el-message-box__header .el-message-box__title){
  text-align: center !important;
}
::v-deep .prompt .el-message-box__btns{
  text-align: center !important;
}
</style>
<style lang="scss" scoped>

@media screen and (min-width: 992px) {
  .footer {
    // background: url("../../assets/img/footer/编组\ 2-min.png");
    background-color: #16161A;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 54px;
    padding-bottom: 35px;
    color: #fff;
    .xs {
      display: none;
    }
    .sl {
      .top {
        padding: 0 12%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .title {
          //margin-bottom: 42px;
          p {
            font-weight: bold;
            line-height: 31px;
            &:last-child {
              width: 60px;
              height: 2px;
              background: var(--theme-warn-color);
              margin-top: 16px;
            }
          }
        }
        .left {
           width: 46%;
          .title {
            margin-left: 24%;
            margin-bottom: 42px;
          }
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            margin-left: 24%;
            font-size: 16px;
            img {
              height: 42px;
              width: 42px;
              margin-right: 18px;
            }
          }
        }
        .right {
          width: 46%;
          .form-item {
            margin-top: 15px;
            display: flex;
            align-items: center;
            label {
              width: 96px;
              display: flex;
              align-items: center;
              .require {
                margin: 6px 6px 0 0;
                color: #FF3131;
              }
            }
            .item1 {
              flex: 1;
              height: 62px;
              margin-left: 12px;
              border-bottom: 1px solid #fff;
              input {
                //flex: 1;
                height: 100%;
                width: 100%;
                background: transparent !important;
                border: none !important;
                //border-bottom: 1px solid #fff !important;
                font-size: 20px;
                // color: white;
                font-family: Oswald-Light;
                font-size: 16px;
                &::placeholder {
                  // color: white;
                  font-size: 16px;
                  font-family: Oswald-Light;
                }
                &:focus-within {
                  outline: none !important;
                }
              }
              .valid {
                height: 16px;
                font-size: 12px;
                color: #FF3131;
              }
              .success {
                border-bottom: 1px solid #fff;
              }
              .error {
                border-bottom: 1px solid red;
              }
              label {
                font-family: Oswald-Light;
                width: 98px;
                height: 62px;
                text-align: left;
                line-height: 62px;
                margin-right: 16px;
              }
              input {
                width: 100%;
                //width: 230px;
                font-family: Oswald-Light;
                flex: 1;
                line-height: 76px;
                font-size: 16px;
                &:last-child {
                  width: 100%;
                }
                &::placeholder {
                  font-size: 16px;
                }
              }
            }
          }
          .item2 {
            height: 104px;
            input {
              width: 100%;
              height: 104px;
              font-size: 16px;
            }
          }
          .btn {
            margin-top: 30px;
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: var(--theme-warn-color);
            border-radius: 7px;
            cursor: pointer;
            color: #fff;
            p {
              //font-family: Helvetica;
              font-weight: 600;
            }
          }
        }
      }
      .bottom {
        text-align: center;
        line-height: 24px;
        margin-top: 50px;
      }
    }
  }
  .normal .top .right input {
    border-bottom: 1px solid #414141 !important;
  }
}
@media screen and (max-width: 992px) {
  .footer .top .right {
    margin: 30px 0;
    .item1 {
      height: auto;
      flex-wrap: wrap;
    }
  }
  .footer {
    // background: url("../../assets/img/footer/编组\ 2-min.png");
    background-color: #16161A;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    //padding-top: 54px;
    padding-bottom: 35px;
    color: #fff;
    .sl {
      display: none;
    }
    .xs {
      padding: 0 16px;
      .top {
        display: flex;
        //justify-content: space-around;
        flex-wrap: wrap;
        .title {
          //margin-bottom: 42px;
          p {
            font-weight: bold;
            line-height: 31px;
            &:last-child {
              width: 60px;
              height: 2px;
              background: var(--theme-warn-color);
              margin-top: 16px;
            }
          }
        }
        .left {
          .title {
            margin-bottom: 42px;
          }
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
            font-size: 16px;
            img {
              height: 42px;
              width: 42px;
              margin-right: 18px;
            }
          }
        }
        .right {
          width: 630px;
          input {
            height: 100%;
            background: transparent !important;
            border: none !important;
            //border-bottom: 1px solid #fff !important;
            font-size: 20px;
            // color: white;
            font-family: Oswald-Light;
            font-size: 16px;
            &::placeholder {
              // color: white;
              font-size: 16px;
              font-family: Oswald-Light;
            }
            &:focus-within {
              outline: none !important;
            }
          }
          .form-item{
            .item1 {
              display: flex;
              justify-content: space-between;
              height: 62px;
              margin-top: 15px;
              border-bottom: 1px solid #fff;
              label {
                display: flex;
                align-items: center;
                font-family: Oswald-Light;
                width: 98px;
                height: 62px;
                text-align: left;
                line-height: 62px;
                margin-right: 16px;
                .require {
                  margin: 6px 6px 0 0;
                  color: #FF3131;
                }
              }
              input {
                //width: 230px;
                font-family: Oswald-Light;
                flex: 1;
                line-height: 76px;
                font-size: 16px;
                &:last-child {
                  width: 100%;
                }
                &::placeholder {
                  font-size: 16px;
                }
              }
            }
            .valid {
              height: 16px;
              font-size: 12px;
              color: #FF3131;
            }
          }

          .item2 {
            height: 104px;
            input {
              width: 100%;
              height: 104px;
              font-size: 16px;
            }
          }
          .btn {
            margin-top: 30px;
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            background: var(--theme-warn-color);
            border-radius: 7px;
            cursor: pointer;
            color: #fff;
            p {
              //font-family: Helvetica;
              font-weight: 600;
            }
          }
        }
      }
      .bottom {
        text-align: center;
        line-height: 24px;
        margin-top: 16px;
      }
    }

  }
  .normal .top .right input {
    border-bottom: 1px solid #414141 !important;
  }
}
</style>